.access-section {
    background-color: #F5F5F5;
    display: flex;
    padding: 50px 100px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.access-section .text .title {
    margin: 0;
    font-weight: 400;
    font-size: 2rem;
}

.access-section .text .description{
    padding: 0 30px 0 0;
}

.access-section .text .description h2{
    font-size: 1.5rem;
    font-weight: 400;
}
.access-section .text .description h3{
    margin: 30px 0 10px 0;
    border-bottom: 2px solid #BA9143;
    width: fit-content;
}
.access-section .text .description p{
    margin:0;
}

@media screen and (max-width: 820px) {

    .access-section {
        flex-direction: column;
        padding: 50px 10px;
    }
    .access-section .text .description h2{
    font-size: 1.2rem;
    font-weight: 800;
}
    .access-section .map{
        margin-top: 30px;
        position: relative;
        width: 100%;
        padding-top: 56.25%;
    }
    .access-section .map iframe {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}