.program-section {
    background-color: #F5F5F5;
    padding: 4rem 0;
    font-family: serif;
}
.fadeIn {
    animation: fadeIn 200ms linear both;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fadeOut {
    animation: fadeOut 200ms linear both;
}

@keyframes fadeOut {
    100% {
        opacity: 0;
    }

    0% {
        opacity: 1;
    }
}

.program-section .composer{
    margin: 0;
    font-size: 1.6rem;
}
.program-section .music {
margin: 0;
    font-size: 2rem;
}
.program-section .en {
    margin: 0;
    font-size: 1.6rem;
}

.program-section .item {
    margin: 2.5rem 0;
    text-align: center;
    font-family: serif;
}

.program-section .overview {
    background-color: #bdbdbd;
    padding: 50px 100px;
    font-size: 1.6rem;
    text-align: center;
}

.program-section .program {
    padding-bottom: 100px;
}

.program-section .overview .conductor {
    font-size: 2rem;
}

.program-section .overview .date {
    font-size: 2.5rem;
}
.program-section .overview .detail {
    font-size: 1.2rem;
}

@media screen and (max-width: 820px){
    .program-section {
            padding: 2rem 0;
        }
    .program-section .item .composer{
        font-size: 1rem;
    }
    .program-section .music {
    font-size: 1.5rem;
    }
    .program-section .en {
        font-size: 1rem;
    }

    .program-section .overview {
    padding: 10px 50px;
    font-size: 1.2rem;
    text-align: center;
}

.program-section .overview .conductor {
    font-size: 1.5rem;
}

.program-section .overview .date {
    font-size: 1.7rem;
}

.program-section .overview .detail {
    font-size: 1rem;
}
}