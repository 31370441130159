.about-section {
    background-color: #E4E4DB;
    position: relative;
    font-family: serif;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #474747;
}

.about-section .img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    background: url("../../images/nuso-image.jpeg");
    background-position: center;
    background-size: cover;
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translate(-50%);
}

.about-section .title {
    font-size: 2rem;
    margin: 0;
    padding-top: 150px;
    text-align: center;
}

.about-section .text {
    width: 600px;
    padding: 50px 0;
    text-align: center;
    line-height:2.2rem;
}

@media screen and (max-width: 820px) {
    .about-section .title {
            font-size: 1.2rem;
            margin: 0;
            padding-top: 150px;
            text-align: center;
        }
    .about-section .text {
            width: 80%;
            font-size: 1rem;
            padding: 15px 0;
            text-align: center;
            line-height: 2.2rem;
        }
}