.footer  {
    padding: 100px 0 50px 0;
    background-color: #E4E4DB;
    color: #474747;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer img {
    width: 200px;
}

footer .nav-list {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    margin: 50px 0;
    padding: 0;
}

footer .nav-list .nav-list-item {
    margin: -10px 2rem 0 2rem;
    padding-top: 10px;
    transition: all .5s ease;
    border-top: 2px solid rgba(0, 0, 0, 0);
}

footer .nav-list .nav-list-button {
    margin: -10px 2rem 0 2rem;
    padding-top: 10px;
}

footer .nav-list .nav-list-item:hover {
    border-top: 2px solid rgba(0, 0, 0, 1);
}

.footer .icons img{
    margin: 0px 20px;
    width: 50px;
}

.footer .copy {
    margin-top: 30px;
    font-size: 12px;
}

@media screen and (max-width: 820px) {
    .footer .nav-list {
        flex-direction: column;
    }

    .footer .nav-list .nav-list-item {
        margin-bottom: 30px;
    }
    
    .footer .copy {
        margin: 30px;
    }
}