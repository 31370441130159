.header {
    background-color: rgba(255, 255, 255, 0.25);
    margin: 0;
    padding: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
    width: 100vw;
    color: white;
}
.header-parent {
    position: relative;
    width: 100vw;
}

.header .nav-list {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
}

.header .nav-list .nav-list-item {
    margin: -10px 2rem 0 2rem;
    padding-top: 10px;
    transition: all .5s ease;
    border-top: 2px solid rgba(255, 255, 255, 0);
    cursor: pointer;
}

.header .nav-list .nav-list-button{
    margin: -10px 2rem 0 2rem;
    padding-top: 10px;
}

.header .nav-list .nav-list-item:hover {
    border-top: 2px solid white;
}

.not-home-header {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
}