.news-section {
    background-color: #F5F5F5;
    display: flex;
    align-items:center;
    flex-direction: column;
    position: relative;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.news-section .news-section-title{
    color: #474747;
    font-size: 2rem;
    padding-bottom: 1rem;
    margin: 1rem 0 5rem 0;
    border-bottom: 2px solid #474747;
    font-weight: 400;
}

.news-section .news-cards {
    width: calc(100vw - 200px);
    display: flex;
    justify-content: space-between;
}

.news-section .icons {
    position: absolute;
    top: 3rem;
    right: 100px;
}

.news-section .icons img {
    margin-left: 30px;
}

.news-section .view-more {
    box-sizing: border-box;
    text-align: right;
    width: 100vw;
    font-size: 1.5rem;
    margin: 2rem 0 10rem 0;
    padding-right: 100px;
    color: #474747;
transition: all .5s ease;
text-decoration: none;
}
.news-section .view-more:hover {
    color: #7d7d7d;
}

@media screen and (max-width: 820px) {
    .news-section .news-section-title {
        margin: 2rem;
    }
    .news-section .icons {
        position: inherit;
        width: 100vw;
        top: auto;
        right: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

        .news-section .icons img {
            margin: 15px;
        }
    .news-section .news-cards {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .news-section .news-cards .card {
        padding-bottom: 2rem;
    }

        .news-section .view-more {
            font-size: 1rem;
            margin-top: 0;
            margin-bottom: 150px;
    padding-right: 50px;
        }
}