

.hero-img img {
    height: 90vh;
    position: fixed;
    z-index: 0;
}

.hero-color{
    background-color: #8E8C80;
    height: 90vh;
}

.hero-bg {
    position:relative;
    z-index: -1;
}

.hero-title {
    position: absolute;
    top: 45%;
    right: 0;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: white;
    font-family: serif;
}

.hero-title .nuso {
    font-size: 3rem;
    margin: 1rem 0;
}
.hero-title .t123 {
    font-size: 3.5rem;
    margin: 1rem 0;
}

.hero-section .hero-count {
    position: absolute;    
    right: 5rem;
    bottom: 20vh;
    background-color: #474747;
    padding: 10px 50px;
}

.hero-section .hero-count .date{
    color: white;
}

.hero-section .hero-count .count {
    display: flex;
}

.hero-section .hero-count .colon {
    font-size: 3rem;
}

.hero-section .hero-count .item {
    display: flex;
    flex-direction: column;
}

.hero-section .hero-count .count .item .time div {
    margin: 2px;
    padding: 3px;
    background-color: #ffffff10;
    font-size: 3rem;
    color: white;
}

.hero-section .hero-count .count div {
    display: flex;
    margin: 0 2px;
    color: rgba(255, 255, 255, 0.5);
}