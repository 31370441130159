.button {
    border: solid white;
    padding: 0.5rem 1rem;
    border-radius:100rem;
    color: white;
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.5s;
    cursor: pointer;
}
.button:hover{
    color: black;
    border-color: white;
    background-color: white;
}

.black {
    border: solid #474747;
    color: #474747;
}

.black:hover {
    color: white;
        border-color: #474747;
        background-color: #474747;
}