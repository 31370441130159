.guideline-container {
    padding-top: 10rem;
    margin-bottom: 5rem;
}

.guideline-container .guideline-title {
    text-align: center;
}

.guideline-container .guideline-title h2 {
    font-size: 1.4rem;
    border-bottom: #333 solid 3px;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.guideline-container .please {
    margin-top: 2rem;
}

.guideline-container .guideline {
    margin-top: 2rem;
}