.contact-section {
    background-color: #f5f5f5;
    position: relative;
    min-height: 500px;
}

.contact-section .title h2{
    background-color: #f5f5f5;
    padding: 20px;
    border-left: #474747 3px solid;
    position: absolute;
    z-index: 1;
    top: 30px;
    left: 100px;
}
.contact-section .title img{
    position: absolute;
    height: 400px;
    top: 30px;
    left: 160px;
}

@media screen and (max-width: 820px) {
    .contact-section {
        padding: 0 10px;
    }
    .contact-section .title h2 {
        margin: 0;
        padding: 0;
        position: inherit;
    }    
    .contact-section .title img {
        display: none;
        position: inherit;
    }
}