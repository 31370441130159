.tickets-container {
    padding-top: 10rem;
}

.tickets-container .tickets-title {
    text-align: center;
}

.tickets-container .tickets-title h2 {
    font-size: 1.4rem;
    border-bottom: #333 solid 3px;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.cp_table *,
.cp_table *:before,
.cp_table *:after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.cp_table {
    width: 100%;
    border-collapse: collapse;
}

.cp_table th,
.cp_table td {
    padding: 10px;
    border: 1px solid #dddddd;
}

.cp_table th {
    width: 30%;
    text-align: left;
    background: #f4f4f4;
}

@media only screen and (max-width:480px) {
    .cp_table {
        margin: 0;
    }

    .cp_table th,
    .cp_table td {
        width: 100%;
        display: block;
        border-top: none;
    }

    .cp_table tr:first-child th {
        border-top: 1px solid #dddddd;
    }
}

/* ボタン共通設定 */
.btn06 {
    /*矢印の基点とするためrelativeを指定*/
    position: relative;
    /*ボタンの形状*/
    text-decoration: none;
    display: inline-block;
    background: #333;
    color: #fff;
    padding: 10px 40px 10px 30px;
    border-radius: 10px;
    text-align: center;
    outline: none;
    /*アニメーションの指定*/
    transition: ease .2s;
}

.btn06:hover {
    background: #555;
}


/* 矢印が右に移動 */

.btnarrow1::after {
    content: '';
    /*絶対配置で矢印の位置を決める*/
    position: absolute;
    top: 42%;
    right: 15px;
    /*矢印の形状*/
    width: 5px;
    height: 5px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    /*アニメーションの指定*/
    transition: all .3s;
}

/*hoverした際の移動*/
.btnarrow1:hover::after {
    right: 11px;
}

.cp_table td .caption
{
    font-size: 0.8rem;
}

.tickets-container .info {
    margin: 1rem 0 2rem;
}

.tickets-container .please {
    margin: 2rem 0 2rem;
    background-color: #fff8e8;
    padding: 1rem;
}

.tickets-container h2 {
    font-size: 1.2rem;
}
