.news-card {
    width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    height: 400px;
    overflow: hidden;
    position: relative;
}

.news-card .img{
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
    transition: all .5s ease;
}

.news-card:hover .img {
    transform: scale(1.3);
}

.news-card .detail {
    background-color: white;
    padding: 30px;
    position: absolute;
    top: 200px;
    height: 200px;
}

.news-card .detail .title {
    font-size: 1.2rem;
    width: 240px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.news-card .detail .auther {
    color: #9AB58B;
    margin: 5px 0;
}

.news-card .detail .text {
    width: 240px;
    font-size: 14px;
    color: #474747;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
}