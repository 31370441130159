.sp-hero {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.sp-hero img {
    height: 90vh;
    object-fit: cover;
    position: fixed;
    z-index: -1;
}

.sp-hero .text-container {
    height: 90vh;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sp-hero .text-container h1 {
    margin: 10px 0;
    font-family: serif;}

.sp-hero .hero-count {
    margin: 20px 0;
    background-color: #474747c1;
    padding: 10px 10px;
}

.sp-hero .hero-count .date {
    color: white;
}

.sp-hero .hero-count .count {
    display: flex;
}

.sp-hero .hero-count .colon {
    font-size: 2rem;
}

.sp-hero .hero-count .item {
    display: flex;
    flex-direction: column;
}

.sp-hero .hero-count .count .item .time div {
    margin: 2px;
    padding: 3px;
    background-color: #ffffff10;
    font-size: 2rem;
    color: white;
}

.sp-hero .hero-count .count div {
    display: flex;
    margin: 0 2px;
    color: rgba(255, 255, 255, 0.5);
}