.conductor-section {
    padding: 50px 100px;
    background-color: #F5F5F5;
    display: flex;
    font-family: serif;
    color: #474747;
}

.conductor-section .image-container{
    width: 30rem;
}

.conductor-section img {
    width: 100%;
}

.conductor-section .text {
    margin-left: 50px;
    width: 60%;
}

.conductor-section .text .name {
    font-size: 2rem;
    margin: 0 0 2rem 0;
}

.conductor-section .description{
    margin-bottom: 2rem;
}

.conductor-section .link {
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    border: 2px solid #5c7452;
    color: #5c7452;
    text-decoration: none;
    transition: all 0.3s;
}

.conductor-section .link:hover{
    color: #80a86f;
    border: 2px solid #80a86f;
}

@media screen and (max-width: 820px) {
    .conductor-section {
        flex-direction: column;
        padding: 50px 50px;
    }

        .conductor-section .image-container {
            width: 100%;
        }
.conductor-section .image-container p {
    margin: 5px 0;
}

.conductor-section .text {
    margin: 0;
    width: 100%;
}
.conductor-section .text .name {
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}
}