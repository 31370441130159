.hands-up-container {
    padding-top: 10rem;
    margin-bottom: 5rem;
}

.hands-up-container .hands-up-title {
    text-align: center;
}

.hands-up-container .hands-up-title h2 {
    font-size: 1.4rem;
    border-bottom: #333 solid 3px;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
}

.hands-up-container .hands-up-content ol li {
    padding: 1rem 0;
}

.hands-up-container .hands-up-welcome {
    text-align: center;
}