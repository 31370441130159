/*============
nav
=============*/
nav {
    display: block;
    position: fixed;
    top: 0;
    left: -400px;
    bottom: 0;
    width: 400px;
    background: #F5F5F5;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transition: .5s;
    z-index: 3;
}

.open nav {
    left: 0;
}

nav .inner {
    padding: 40px;
}

nav .inner ul {
    list-style: none;
    margin: 50px 0 0 0;
    padding: 0;
    display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
}

nav .inner ul li {
    position: relative;
    margin: 0;
}

nav .inner ul li p {
    display: block;
    color: #333;
    font-size: 1.2rem;
    padding: 1em;
    text-decoration: none;
    transition-duration: 0.2s;
}

nav .inner ul li p:hover{
    color: rgb(147, 147, 147);
}

@media screen and (max-width: 767px) {
    nav {
        left: -240px;
        width: 240px;
    }
}

/*============
@keyframes
=============*/
@keyframes equalizer01 {
    0% {
        width: 70%;
    }

    10% {
        width: 50%;
    }

    20% {
        width: 100%;
    }

    30% {
        width: 10%;
    }

    40% {
        width: 50%;
    }

    50% {
        width: 70%;
    }

    60% {
        width: 50%;
    }

    70% {
        width: 10%;
    }

    80% {
        width: 100%;
    }

    90% {
        width: 10%;
    }

    100% {
        width: 70%;
    }
}

@keyframes equalizer02 {
    0% {
        width: 30%;
    }

    10% {
        width: 20%;
    }

    20% {
        width: 40%;
    }

    30% {
        width: 10%;
    }

    40% {
        width: 20%;
    }

    50% {
        width: 30%;
    }

    60% {
        width: 20%;
    }

    70% {
        width: 10%;
    }

    80% {
        width: 40%;
    }

    90% {
        width: 10%;
    }

    100% {
        width: 30%;
    }
}

/*============
.toggle_btn
=============*/
.toggle_btn {
    display: block;
    position: fixed;
    top: 40px;
    left: 30px;
    width: 30px;
    height: 30px;
    transform: translate(0, 0);
    transition: all .5s;
    cursor: pointer;
    z-index: 3;
}

.open .toggle_btn {
    left: 430px;
}

.toggle_btn span {
    display: block;
    position: absolute;
    left: 0;
    width: 30px;
    height: 2px;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    transition: all .5s;
}

.toggle_btn span:nth-child(1) {
    top: 4px;
}

.toggle_btn span:nth-child(2) {
    top: 14px;
}

.toggle_btn span:nth-child(3) {
    bottom: 4px;
}

.open .toggle_btn span {
    background-color: #fff;
}

.open .toggle_btn span:nth-child(1) {
    -webkit-transform: translateY(10px) rotate(-315deg);
    transform: translateY(10px) rotate(-315deg);
}

.open .toggle_btn span:nth-child(2) {
    opacity: 0;
}

.open .toggle_btn span:nth-child(3) {
    -webkit-transform: translateY(-10px) rotate(315deg);
    transform: translateY(-10px) rotate(315deg);
}

/*============
#mask
=============*/
#mask {
    display: none;
}

.open #mask {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .8;
    z-index: 2;
    cursor: pointer;
    transition: all .5s;
}

@media screen and (max-width: 820px) {
nav .inner ul li p {
        font-size: 1rem;
        padding: 0.8rem;
    }
}